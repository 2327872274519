<template>
  <div>
    <div
      v-if="submited==true"
      class="auth-wrapper auth-v1"
    >
      <v-progress-circular
        :size="100"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>

      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>
    </div>

    <div
      v-else
      class="auth-wrapper auth-v1"
    >
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :top="true"
        :right="true"
        transition="scroll-x-reverse-transition"
      >
        {{ snackbar.message }}
      </v-snackbar>
      <div class="auth-inner bienvenu">
        <v-card class="auth-card">
          <!-- logo -->

          <v-card-title class="d-flex align-center justify-center py-2">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold text-black">
                BIENVENUE
              </h2>
            </router-link>
          </v-card-title>
          <!-- title -->

          <v-card-text class="text-center text-black">
            Sur l'application qui permet de gérer les décodeurs de tous vos collaborateurs de façon efficace
          </v-card-text>
          <video
            playsinline
            autoplay
            muted
            loop
            poster="../assets/images/misc/image_login.gif"
            width="100%"
            max-height="250px"
          >
            <source
              :src="video"
              type="video/mp4"
            >
          </video>

          <v-card-text class="text-center text-sm text-black">
            En cas d'impossibilité à vous connecter, veuillez nous joindre Via les contacts ci-après :
          </v-card-text>
          <v-card-text class="text-center text-small text-info">
            <v-icon :color="$vuetify.theme.dark ? '#0e7f05' : '#0e7f05'">
              {{ icons.mdiWhatsapp }}
            </v-icon>
            {{ seniorPhoneNumber }}
            <br>
            <v-icon
              class="text-small"
              :color="$vuetify.theme.dark ? '#4267b2' : '#4267b2'"
            >
              {{ icons.mdiEmail }}
            </v-icon>
            suivi_corporate@sygalin.com
            <br>
            <v-icon
              class="text-small"
              :color="$vuetify.theme.dark ? '#0e7f05' : '#0e7f05'"
            >
              {{ icons.mdiPhone }}
            </v-icon>
            8292
          </v-card-text>
        </v-card>
      </div>
      <div class="auth-inner">
        <v-card class="auth-card-left">
          <!-- logo -->

          <v-card-title class="d-flex align-center justify-center mt-4 py-2">
            <v-img
              :src="require('@/assets/images/logos/corp_logo.svg')"
              max-height="100px"
              max-width="100px"
              alt="logo"
              contain
              class="me-3 logo"
            ></v-img>
            <router-link
              to="/"
              class="d-flex align-center"
            >
            </router-link>
          </v-card-title>

          <v-card-title class="d-flex align-center justify-center pt-4">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <h2 class="text-2xl font-weight-semibold text-danger">
                AUTHENTIFICATION
              </h2>
            </router-link>
          </v-card-title>
          <div
            v-if="hasError('dataError')"
            class="text-center"
          >
            <v-alert
              type="error"
              dense
            >
              {{ errors.dataError }}
            </v-alert>
          </div>
          <!-- title -->
          <!-- login form -->
          <v-form
            ref="loginForm"
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="login"
              :prepend-inner-icon="icons.mdiAccount"
              :error-messages="errors.login"
              :rules="loginRules"
              label="Login"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6 input"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :error-messages="errors.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :prepend-inner-icon="icons.mdiLock"
              label="Mot de passe"
              :rules="pwdRules"
              required
              outlined
              dense
              hide-details="auto"
              class="mb-6 input"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-btn
                color="primary"
                :disabled="accountBlocked"
                @click="submit"
              >
                <v-icon>{{ icons.mdiLogin }}</v-icon>
                Connexion
              </v-btn>

              <label
                class="mt-1"
                @click="opendialog()"
              >
                <a><b>Mot de passe oublié ?</b> </a>
              </label>
            </div>
            <br>
            <div
              v-if="attempts <= maxLoginAttempts - 1"
              class="error--text"
            >
              {{ errorMessage }}
            </div>
          </v-form>
        </v-card>
      </div>
      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
      >
    </div>
    <!--    Réinitialisation du mot de passe -->
    <v-dialog
      v-model="dialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span>Réinitialisation du mot de passe </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form
              ref="updatepassword"
            >
              <template>
                <v-row>
                  <v-col
                    cols="12"
                    offset-md="2"
                    md="8"
                  >
                    <span>Veuillez entrer votre login pour continuer(*)</span>
                    <br>
                    <br>
                    <v-text-field
                      v-model="login"
                      :error-messages="errors.login"
                      :rules="loginRules"
                      label="Login"
                      required
                      outlined
                      dense
                      hide-details="auto"
                      class="mb-6 input"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            @click="sendmail()"
          >
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Code OTP a la connexion -->
    <v-dialog
      v-model="dialogotp"
      max-width="500px"
      @input="handleDialogClose"
    >
      <v-card>
        <v-card-title>
          <span>Code OTP  </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <span class="d-sm-flex align-center justify-center">
            Veuillez entrer le code OTP reçu par e-mail ou par SMS
          </span>
        </div>
        <v-card-text>
          <v-container>
            <v-row class="mt-10">
              <v-col
                cols="12"
                class="d-sm-flex align-center justify-center mt-n8"
              >
                <v-otp-input
                  ref="otp"
                  v-model="otp"
                  separator=""
                  input-classes="otp-input"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div
            v-if="dialogotp"
            class="flex"
          >
            <span
              v-if="!isCountdownReset || countdownSeconds === 0"
              :class="{ 'disabled-label': countdownSeconds > 0 }"
              @click="sendotp()"
            >
              <h6><a><b>Renvoyer le code <span v-if="showCountdown"> | {{ countdownSeconds }} sec </span> </b> </a></h6>
            </span>
          </div>

          <v-btn
            color="primary"
             class='text-capitalize'
            @click="validate()"
          >
            Connexion
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline,import/no-extraneous-dependencies
import {
  mdiWhatsapp,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccount,
  mdiEmail,
  mdiCellphone,
  mdiLock,
  mdiLogin,
  mdiPhone,
  mdiSendOutline,
} from '@mdi/js'

import { reactive, ref, watch } from '@vue/composition-api'
import Http from '@/helpers/http'
import Session from '@/helpers/session'
import moment from 'moment'
import useDynamicConstant from '@/helpers/useDynamicConstant'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/named
import video from '../assets/images/misc/corporatevideo.mp4'

export default {
  setup() {
    // eslint-disable-next-line camelcase
    const mail_send = ref(false)
    const loginForm = ref(null)
    const updatepassword = ref(null)
    const isPasswordVisible = ref(false)
    const login = ref('')
    const password = ref('')
    const otp = ref('')
    // eslint-disable-next-line camelcase
    const errors = ref({})
    const submited = ref('false')
    const loginRules = [
      v => !!v || 'Le login est réquis',
      v => (v && v.length <= 20) || 'Le login doit avoir moins de 20 caractères',
    ]

    const seniorPhoneNumber = '675 877 984'
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]
    const accountBlocked = ref(false)
    const pwdRules = [
      v => !!v || 'Le login est réquis',
      v => (v && v.length <= 20) || 'Le mot de passe doit avoir moins de 20 caractères',
    ]
    const form = ref(null)
    const snackbar = reactive({
      active: false,
      message: '',
      color: 'success',
      timeout: 15000,
    })

    const dialogotp = ref(false)
    const countdownSeconds = ref(60)

    const timer = ref(false)
    const showCountdown = ref(true)
    const isCountdownReset = ref(false)

    const startCountdownFn = () => {
      timer.value = setInterval(() => {
        if (countdownSeconds.value > 0) {
          countdownSeconds.value--
        } else {
          stopCountdown()
          showCountdown.value = false
        }
      }, 1000)
    }
    const stopCountdown = () => {
      clearInterval(timer.value)
    }

    const resetCountdown = () => {
      countdownSeconds.value = 60
      isCountdownReset.value = true
      showCountdown.value = true
      setTimeout(() => {
        isCountdownReset.value = false
      })
      startCountdownFn()
    }

    let attempts = ref(null)
    const errorMessage = ref('')
    const maxLoginAttempts = ref(5)

    const submit = () => {
      errors.value = {}
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return
      const article = {
        login: login.value,
        password: password.value,
      }
      submited.value = true
      Http.post(
        'signin',
        article,
      ).then(response => {
        if (response && response.status === 200) {
          if (response.message !== 'error') {
            Session.set('accessToken', response.data.token)

            if (parseInt(response.data.otpStatus) === 1) {
              genrateOtpFn()
              dialogotp.value = true
              startCountdownFn()
            } else {
              getUserData()
            }
          } else {
            attempts.value = 0
            errorMessage.value = response.data
            submited.value = false
          }
        }
      })
        .catch(error => {
          attempts.value = error.data.data.remainingAttempts
          console.log('erreur', error)
          if (attempts.value > 0) {
            errorMessage.value = `Il vous reste ${attempts.value} tentative${attempts.value <= 1 ? '' : 's'}`
            maxLoginAttempts.value = error.data.data.maxLoginAttempts
            submited.value = false
          } else {
            blockAccount()
          }

          submited.value = false

          // TODO: Next Update - Show notification
        })
    }

    const genrateOtpFn = () => {
      const sended = 0
      Http.post('generate-otp', { login: login.value, sended })
        .then(response => {
          if (response.status === 200) {
            snackbar.message = response.message
            snackbar.color = 'success'
            snackbar.show = true
          }
        })
        .catch(error => {
          if (error.status === 400) {
            snackbar.message = error.data.errors
          } else if (error.status === 401) {
            snackbar.message = error.data
          } else {
            snackbar.message = 'Votre connexion internet est instable'
          }
          snackbar.color = 'error'
          snackbar.show = true
          console.log(snackbar)
        })
    }

    const getUserData = () => {
      Http.get(
        'get-user-data',
      ).then(response => {
        localStorage.setItem('contrats', JSON.stringify(response.data.contrats))
        localStorage.setItem('customer', JSON.stringify(response.data.customer))
        localStorage.setItem('defaultContrat', JSON.stringify(response.data.defaultContrat))
        localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
        localStorage.setItem('defaultRole', JSON.stringify(response.data.defaultRole))
        localStorage.setItem('menu', JSON.stringify(response.data.menu))
        localStorage.setItem('roles', JSON.stringify(response.data.roles))
        const sesPer = { Debut: moment().startOf('month').format('YYYY-MM-DD'), Fin: moment().format('YYYY-MM-DD') }
        localStorage.setItem('periode', JSON.stringify(sesPer))

        const userData = response.data.user
        Session.set('userData', JSON.stringify(userData))

        const { ROLE_SUPER_ADMIN } = useDynamicConstant()

        const role = userData.roles[0]

        if (role.id !== ROLE_SUPER_ADMIN) {
          Http.get(`get-info-from-wholesaler/${response.data.defaultContrat.id}`)
            // eslint-disable-next-line no-shadow
            .then(response => {
              const clientInfo = response.data[0]
              localStorage.setItem('clientInfo', JSON.stringify(clientInfo))

              router.push('/')
              submited.value = false
            })
            .catch(error => {
              console.log(error)
              submited.value = false
            })

          Http.get('get-api-status')
            // eslint-disable-next-line no-shadow
            .then(response => {
              Session.set('API_CANAL_STATUS', response.data.status)
            }).catch(error => {
              console.error(error)
            })
        } else {
          router.push('/')
        }
      })
        .catch(error => {
          submited.value = false

          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login! :', error)
        })
    }

    const validate = () => {
      dialogotp.value = false
      const article = {
        login: login.value,
        password: password.value,
      }
      Http.post('validate-otp', { login: article.login, otp: otp.value.otp.toString().replaceAll(',', '') })
        .then(response => {
          if (parseInt(response.status) === 200) {
            getUserData()
          } else {
            /// TODO
          }
        })
        .catch(error => {
          dialogotp.value = true
          if (error.status === 400) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
          submited.value = false
        })
        .catch(error => {
          dialogotp.value = true
          if (error.status === 400) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
          submited.value = false
        })
    }

    const blockAccount = () => {
      Http.post(
        'lock-account', { login: login.value },
      ).then(response => {
        if (response.status === 200) {
          accountBlocked.value = true
          errorMessage.value = response.message
        }
      })
        .catch(error => {
          console.log('erreur', error)
        })
    }

    function handleOnComplete(value) {
      otp.value = value
    }

    return {
      LocalNameRules,
      isPasswordVisible,
      login,
      handleOnComplete,
      otp,
      password,
      submit,
      validate,
      startCountdownFn,
      genrateOtpFn,
      getUserData,
      stopCountdown,
      resetCountdown,
      blockAccount,
      errors,
      dialog: false,
      dialogotp,
      loginRules,
      pwdRules,
      form,
      submited,
      video,
      mail_send,
      snackbar,
      seniorPhoneNumber,
      editedIndex: -1,
      defaultItem: {},
      countdownSeconds,
      isCountdownReset,
      timer,
      showCountdown,
      accountBlocked,
      attempts,
      errorMessage,
      maxLoginAttempts,

      // icons
      icons: {
        mdiAccount,
        mdiEmail,
        mdiCellphone,
        mdiLock,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiWhatsapp,
        mdiLogin,
        mdiPhone,
        mdiSendOutline,
      },
      loginForm,
      updatepassword,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },

  methods: {
    opendialog() {
      this.dialog = true
    },

    handleDialogClose() {
      this.stopCountdown()
    },

    sendotp() {
      const sended = 1
      if (this.countdownSeconds === 0 && !this.isCountdownReset) {
        Http.post('generate-otp', { login: this.login, sended })
          .then(response => {
            if (response.status === 200) {
              this.snackbar.message = response.message
              this.snackbar.color = 'success'
              this.snackbar.show = true
            }
          })
          .catch(error => {
            if (error.status === 400) {
              this.snackbar.message = error.data.errors
            } else if (error.status === 401) {
              this.snackbar.message = error.data
            } else {
              this.snackbar.message = 'Vérifier votre connexion internet'
            }
            this.snackbar.color = 'error'
            this.snackbar.show = true
            console.log(error)
          })
        this.showCountdown = true
        this.resetCountdown()
      }
    },

    sendmail() {
      Http.post('/password-reset-request', { login: this.login })
        .then(response => {
          if (response.status === 200) {
            this.snackbar.message = response.message
            this.snackbar.color = 'success'
            this.snackbar.show = true
          }
        })
        .catch(error => {
          if (error.status === 400) {
            this.snackbar.message = error.data.errors
          } else if (error.status === 401) {
            this.snackbar.message = error.data
          } else {
            this.snackbar.message = 'Votre connexion internet est instable'
          }
          this.snackbar.color = 'error'
          this.snackbar.show = true
        })
      this.dialog = false
    },

    hasError(name) {
      return (name in this.errors)
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedIndex = -1
      })
    },

    handleOnChange(value) {
      console.log('OTP changed: ', value)
    },
  },
}

</script>

<style lang="scss">
@import '~@/@core/preset/preset/pages/auth.scss';
.errorMessage {
  color: #f20808 !important;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 8px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled-label {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.center-snackbar {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
